<template>
  <div class="works_list">
    <el-card>
      <div class="works_list_title">{{ activeName }}</div>
      <el-form :inline="true" label-width="60px" label-position="left">
        <el-form-item label="学校：">
          <el-input v-model="queryInfo.school" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="姓名：">
          <el-input v-model="queryInfo.enrollName" placeholder="请输入" />
        </el-form-item>
        <el-form-item>
          <el-button class="btn" @click="search">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :header-cell-style="{
          backgroundColor: '#F0F0FF',
          color: '#151515',
          fontWeight: 'bold',
        }"
        :data="tableData"
        style="width: 100%"
        stripe
        class="tab_data"
        height="520px"
      >
        <el-table-column type="index" width="50" label="序号" />
        <el-table-column
          prop="enrollName"
          label="姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="school"
          label="学校"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="grade"
          label="年级"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="clazz"
          label="班级"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="phone"
          label="联系方式"
          align="center"
        ></el-table-column>
        <el-table-column label="图片" align="center">
          <template v-slot="{ row }">
            <span v-for="(item, index) in row.enrollmentFileViews" :key="index">
              <el-popover placement="left" trigger="click" width="600">
                <img :src="item.images" width="100%" />
                <img
                  slot="reference"
                  :src="item.images"
                  :alt="item.images"
                  style="max-height: 70px; max-width: 70px; padding: 5px"
                />
              </el-popover>
            </span>
            <!-- <el-image
              v-for="(item, index) in row.enrollmentFileViews"
              :key="index"
              fit="contain"
              lazy
              :preview-src-list="getPreviewImgList(index)"
              :src="item.images"
            ></el-image> -->
          </template>
        </el-table-column>
        <el-table-column
          prop="file"
          label="附件"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="提交时间"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template v-slot="{ row }">
            <el-button v-if="row.file" type="text" @click="downloadCLick(row)"
              >下载附件</el-button
            >
            <el-button type="text" @click="commentClick(row)">点评</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[30, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
  </div>
</template>

<script>
import { worksMangeList } from "@/api/activity";
import { pagination } from "@/mixins/pagination";
import axios from "axios";
const defaultQueryInfo = Object.freeze({
  pageNumber: 0, // 页码
  pageSize: 10, //每页显示个数
  school: "", //学校
  enrollName: "", //姓名
});
export default {
  name: "worksList",
  mixins: [pagination],
  computed: {
    // title
    activeName() {
      return this.$route.query.activeName;
    },
    //活动id
    activeId() {
      return this.$route.query.id;
    },
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },
  created() {
    this.search();
  },
  data() {
    return {
      queryInfo: { ...defaultQueryInfo },
      tableData: [],
      //srcList: [],
    };
  },
  methods: {
    //点评
    commentClick(row) {
      this.$router.push({
        path: "/activity/worksList/comment",
        query: { id: row.id, activeName: this.activeName, activeId: this.activeId },
      });
    },
    //下载附件
    downloadCLick(row) {
      const url = row.fileKey;
      axios({
        method: "GET",
        url: `${process.env.VUE_APP_BASE_API}/enrollment/urlStr`,
        params: { urlStr: url },
        responseType: "blob",
        headers: {
          // 设置请求头
          Authorization: this.Authorization,
        },
      })
        .then((res) => {
          let blob = new Blob([res.data], {
            type: `application/msword`,
          });
          let href = window.URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
          const a = document.createElement("a"); //创建a标签
          a.style.display = "none";
          a.href = href; // 指定下载'链接
          a.download = row.fileKey.substring(row.fileKey.lastIndexOf("/") + 1); //指定下载文件名
          a.click(); //触发下载
          window.URL.revokeObjectURL(a.href); //释放URL对象
          if (document.body.contains(a)) {
            document.body.removeChild(a); //释放标签
          }
        })
        .catch((err) => {
          this.$message.error("文件下载失败");
        });
      //截取字符串'/'倒数第二次出现的内容
      //const str = row.file.substr(row.file.lastIndexOf('/', row.file.lastIndexOf('/') - 1) + 1)
    },
    //重置查询条件
    reset() {
      Object.assign(this.queryInfo, defaultQueryInfo);
      this.search();
    },
    //获取作品列表的表格数据
    async search() {
      this.queryInfo.activeId = this.activeId;
      const { data: res } = await worksMangeList(this.queryInfo);
      if (res.data) {
        this.tableData = res.data.data;
        this.tableData.map((el) => {
          if (el.file) {
            el.file = el.file.substr(el.file.lastIndexOf("/") + 1);
          }
        });
        // this.srcList = this.tableData.map((el) => {
        //   return el.enrollmentFileViews.map((item) => {
        //     return item.images;
        //   });
        // });
        this.total = res.data.pageTotal;
      } else {
        this.tableData = [];
        this.total = 0;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.works_list {
  height: 620px;
  width: 100%;
  overflow: auto;
  /deep/ .el-card {
    border: none;
    height: 100%;
  }

  // /deep/ .el-button:focus,
  // .el-button:hover {
  //   color: #fff;
  //   border-color: #f8f8ff;
  //   background-color: #f8f8ff;
  // }

  /deep/
    .el-table--striped
    .el-table__body
    tr.el-table__row--striped
    td.el-table__cell {
    background-color: #f8f8ff;
  }

  /deep/
    .el-table--enable-row-hover
    .el-table__body
    tr:hover
    > td.el-table__cell {
    background-color: #f8f8ff;
  }

  .works_list_title {
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    padding: 30px 0 40px 0;
    border-bottom: 1px dashed #e5e5e5;
    text-align: center;
    color: #151515;
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: bold;
  }
}
</style>